<template>
    <div class="block-liste" :class="`block-liste-${type}`">
        <div class="form">
            <input 
                type="text"
                placeholder="Suche..."
                v-model="search"
                class="form-control bg-white search-offers-input"
            >
        </div>

        <div v-if="!loading && offers.length==0" class="mt-2 mb-2">
            {{ search ? 'Keine Anzeigen für "'+search+'" gefunden' : 'Keine Anzeigen vorhanden' }}
        </div>

        <div v-if="loading" class="mt-2">
            <span class="loader loader-sm"></span>
        </div>

        <div 
            v-for="offer in offers"
            :key="offer.id"
            @click="onClickOffer(offer)"
            class="card position-relative d-flex align-items-center" :class="{active: !editId || editId==`${offer.id}`}">
            <div class="offer-image" >
                <div class="bg-muted d-flex align-items-center justify-content-center h-100">
                    <img
                        :class="{'offer-image-cover': offer.image}"
                        :src="offer.image ? offer.image : '/images/logo-dolomitenmarkt.svg'"
                        width="110"
                        height="40"
                        :alt="offer.title"
                    />
                </div>
            </div>
            <div class="offer-box">
                <span v-if="!offer.active" class="text-bold text-danger offer-subtitle">!! Anzeige inaktiv !!</span>
                <span v-if="offer.subtitle" class="text-bold text-muted offer-subtitle">{{ offer.subtitle }}</span>
                <a v-else-if="offer.urlCandidatePool" :href="offer.urlCandidatePool.url" @click.stop="" class="kandidaten-badge active mb-xs-2 mb-md-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.7" height="9.6" viewBox="0 0 13.715 9.601">
                        <path d="M4.114,7.05a2.4,2.4,0,1,0-2.4-2.4A2.4,2.4,0,0,0,4.114,7.05Zm1.646.686H5.582a3.314,3.314,0,0,1-2.936,0H2.469A2.469,2.469,0,0,0,0,10.2v.617A1.029,1.029,0,0,0,1.029,11.85H7.2a1.029,1.029,0,0,0,1.029-1.029V10.2A2.469,2.469,0,0,0,5.76,7.736Zm4.526-.686A2.057,2.057,0,1,0,8.229,4.993,2.058,2.058,0,0,0,10.286,7.05Zm1.029.686h-.081a2.7,2.7,0,0,1-1.894,0H9.258a2.38,2.38,0,0,0-1.194.33A3.136,3.136,0,0,1,8.915,10.2v.823c0,.047-.011.092-.013.137h3.784a1.029,1.029,0,0,0,1.029-1.029,2.4,2.4,0,0,0-2.4-2.4Z" transform="translate(0 -2.25)" fill="currentcolor"></path>
                    </svg>
                    <strong>{{ offer.urlCandidatePool.text }}</strong>
                </a>

                <span class="offer-title text-bold mt-1 mb-1 mb-sm-2">{{ offer.title }}</span>
                <span class="job-time d-block">{{ offer.date }}</span>
            </div>
            <a v-if="offer.canEdit" :href="offer.url" class="position-absolute edit">
                <img src="/images/icons/icon-edit.svg" alt="Icon Edit" />
            </a>
        </div>

        <div v-if="loadingLoadMore" class="mt-2">
            <span class="loader loader-sm"></span>
        </div>

        <div v-if="offers && offers.length && !loading && !hideLoadMore"  class="mt-2">
            <a @click="loadMore">{{ $t('generic.load_more') }}</a>
        </div>

    </div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import debounce from "lodash/debounce"
import emitter from '../plugins/mitt';

export default {
    name: 'ProfileOfferList',
    inject: ['$t'],
    mounted: async function () {
        let parent = document.getElementById('v-profile-offer-list')
        if (parent && parent.dataset.type) {
            this.type = parent.dataset.type
        }
        if (parent && parent.dataset.editId) {
            this.editId = parent.dataset.editId
        }
        this.loadData()
        emitter.on('profile_reload_offer_list', () => {
            this.loadData()
        })
    },
    methods: {
        onClickOffer: function(offer) {
            window.open(offer.url, offer.canEdit ? '_self' : '_blank')
        },
        onSearch: debounce(function(event) {
            this.loadData()
        }, 500),
        loadMore: function() {
            if(this.loadingLoadMore) return
            this.loadingLoadMore = true
            axios
                .post('/api/profile/offers/'+this.type, {
                    search: this.search,
                    offset: this.offers.length
                })
                .then((response) => {
                    this.loadingLoadMore = false
                    if(response.data.hideLoadMore) {
                        this.hideLoadMore = true
                    }
                    this.total = response.data.total
                    this.offers = [...this.offers, ...response.data.offers]
                })
                .catch((error) => {
                    this.loadingLoadMore = false
                    console.log(error)
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },
        loadData: function () {
            if (this.loading) return
            if (this.loadingLoadMore) return
            this.loading = true
            this.hideLoadMore = false
            axios
                .post('/api/profile/offers/'+this.type, {
                    search: this.search,
                    editId: this.editId
                })
                .then((response) => {
                    this.loading = false
                    this.total = response.data.total
                    this.offers = response.data.offers
                    if(response.data.hideLoadMore) {
                        this.hideLoadMore = true
                    }
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error)
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },
    },
    watch: {
        search: function() {
            this.onSearch()
        }
    },
    data() {
        return {
            offers: [],
            editId: null,
            hideLoadMore: false,
            search: '',
            total: 0,
            type: 'xxx',
            loading: false,
            loadingLoadMore: false
        }
    },
}
</script>
<style lang="css" scoped>
.search-offers-input {
    padding: 8px !important;
}
.block-liste {
    background-color: transparent;
}
.search-offers-input {
    background-color: white;
}
.offer-image img.offer-image-cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
</style>