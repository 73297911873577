<template>
    <div class="mainbar">
        <div
            v-if="this.chatEntries.length"
            v-for="chatEntry in this.chatEntries"
            class="chat-item text-no-underline d-flex align-items-center py-2 py-md-3 px-3 position-relative"
            :class="{ new: chatEntry.unread }"
        >
            <a :href="chatEntry.route" @click.prevent="$emit('replace-url', chatEntry.route)" class="overlay"></a>

            <div v-if="chatEntry.customer" class="logo-box">
                <img
                    v-if="chatEntry.customer.type === 'private'"
                    width="20"
                    height="20"
                    src="/images/icons/icon-privatperson.svg"
                    alt="Icon Privatperson"
                />
                <img v-else-if="chatEntry.customer.logo" :src="chatEntry.customer.logo" :alt="'Logo ' + chatEntry.customer.display_name" />
                <strong v-else class="text-muted text-center">{{ chatEntry.customer.display_name }}</strong>
            </div>
            <div class="text-box px-lg-3 mt-2 mt-lg-0">
                <strong class="d-flex align-items-center">
                    {{ chatEntry.title }}
                    <img v-if="chatEntry.badge === 'immo'" class="ms-2" src="/images/immo/bagde-immo.svg" alt="Badge Immomarkt" />
                    <img v-if="chatEntry.badge === 'job'" class="ms-2" src="/images/job/badge-job.svg" alt="Badge Jobmarkt" />
                </strong>
                <span class="message-text mt-1 d-block text-ellipsis">
                    {{ chatEntry.description }}
                </span>
            </div>
            <div class="action-box">
                <span class="text-right text-small text-nowrap text-muted d-block">{{ chatEntry.date }}</span>
                <div class="justify-content-between mt-1 d-none d-lg-flex">
                    <a href="#" @click.prevent="$emit('trash-chat', chatEntry.chat_id)" class="action-button text-no-underline">
                        <img src="/images/icons/icon-trash.svg" alt="Icon Delete" />
                    </a>
                    <a href="#" @click.prevent="$emit('archive-chat', chatEntry.chat_id)" class="action-button text-no-underline">
                        <img src="/images/icons/icon-archiv.svg" alt="Icon Archiv" />
                    </a>
                    <a :href="chatEntry.route" @click.prevent="$emit('replace-url', chatEntry.route)" class="action-button text-no-underline">
                        <img src="/images/icons/icon-reply.svg" alt="Icon Reply" />
                    </a>
                </div>
            </div>
        </div>

        <div v-else class="h-100 d-flex align-items-center justify-content-center p-3">
            <p class="text-muted text-center mt-3">{{ $t('fe.chat.no_entries') }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatList',
    data() {
        return {}
    },
    methods: {
    },
    props: {
        chatEntries: {
            type: Array,
            default: [],
        },
    },
}
</script>
