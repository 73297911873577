<template>


    <div v-if="profileRedirectInfo">
        <div class="alert-warning alert" v-html="profileRedirectInfo.message">
        </div>

        <a class="btn btn-primary btn-small mt-2" v-if="profileRedirectInfo.url" :href="profileRedirectInfo.url">{{ profileRedirectInfo.buttonText }}</a>

    </div>

    <Form
        v-else
        ref="form"
        novalidate
        @submit="onSubmit"
        @invalid-submit="onInvalid"
        @change="onChangeFormValues"
        class="form check-required-fields"
        v-slot="{ errors, values, meta }"
    >
        <template v-if="editId">
            <h3 class="tincx-h5 mt-0 mb-0">Anzeige bearbeiten</h3>
        </template>


        <h3 v-else class="tincx-h5 mt-0 mb-0">Allgemeine Informationen</h3>

        <div class="row-small">

            <label class="d-flex align-items-center mt-4">
                <input type="checkbox" name="active" v-model="active" /><span :class="{'text-success' : active, 'text-danger': !active}">{{  active ? 'Anzeige aktiv und sichtbar' : 'Anzeige inakiv und nicht sichtbar' }}</span>
                <Field type="hidden" name="active" :modelValue="active" />
            </label>

            <OffersPreview
                v-if="editId && formValues"
                @onPreviewClick="onPreviewClick"
                type="auto"
                :data="formValues"
                class="mt-3"
                />

            <Field type="hidden" name="new" :value="editId ? false : true" />
        </div>
        
        <div class="row-small">

            <p class="mt-4 mb-2">
                <strong>Anzeigenart*</strong>
            </p>

            <div class="col-12">
                <div class="form-floating">
                    <Field 
                        as="select"
                        class="form-control"
                        name="auto_offer"
                        :label="$t('auto.entry.auto_offer')"
                        rules="required"
                        :class="{ 'is-invalid': errors.type }"
                    >
                        <option value="" disabled>{{ $t('auto.entry.auto_offer') }} auswählen</option>
                        <option :value="true">{{ $t('auto.auto_offer.true') }}</option>
                        <option :value="false">{{ $t('auto.auto_offer.false') }}</option>
                    </Field>
                    <label>{{ $t('auto.entry.auto_offer') }}</label>
                    <ErrorMessage as="div" name="type" class="invalid-feedback" />
                </div>
            </div>


            <p class="mt-4 mb-0">
                <strong>Basisdaten*</strong>
            </p>

            <div class="col-12 mt-3" v-if="options.categories">
                <div class="form-floating">
                    <Field 
                        as="select"
                        class="form-control"
                        name="category"
                        :label="$t('auto.entry.category')"
                        rules="required"
                        :class="{ 'is-invalid': errors.category }"
                    >
                        
                        <option value="" disabled>{{ $t('auto.entry.category') }} auswählen</option>
                        <option v-for="category in options.categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                    </Field>
                    <label>{{ $t('auto.entry.category') }}</label>
                    <ErrorMessage as="div" name="category" class="invalid-feedback" />
                </div>
            </div>


            <div class="col-12 col-md-6 mt-3">
                <div class="form-floating" v-if="options.brands">
                    <Field 
                        as="select"
                        class="form-control"
                        name="brand"
                        @change="onTitleUpdate"
                        :label="$t('auto.entry.brand')"
                        rules="required"
                        :class="{ 'is-invalid': errors.brand }"
                    >
                        <option value="">Marke auswählen</option>
                        <option value="0001" disabled="">Top Marken</option>
                        <option v-for="o in options.brands.filter(i => i.top)" :key="o.id" :value="o.id">{{ o.name }}</option>
                        <option value="0001" disabled="">Andere Marken</option>
                        <option v-for="o in options.brands.filter(i => !i.top)" :key="o.id" :value="o.id">{{ o.name }}</option>
                    </Field>
                    <label>{{ $t('auto.entry.brand') }}</label>
                    <ErrorMessage as="div" name="brand" class="invalid-feedback" />
                </div>
            </div>

            <div class="col-12 col-md-6 mt-3">
                <div class="form-floating">
                    <Field 
                        type="text"
                        class="form-control"
                        name="modelname"
                        @input="onTitleUpdate"
                        :label="$t('auto.entry.modelname')"
                        :placeholder="$t('auto.entry.modelname')"
                        rules="required"
                        :class="{ 'is-invalid': errors.modelname }"
                    />
                    <label>{{ $t('auto.entry.modelname') }}</label>
                    <ErrorMessage as="div" name="modelname" class="invalid-feedback" />
                </div>
            </div>

            <div class="col-12 col-md-6 mt-3">
                <div class="form-floating">
                    <Field 
                        type="date"
                        @input="onChangeFormValues"
                        class="form-control"
                        name="first_registration"
                        :label="$t('auto.entry.first_registration')"
                        rules="required"
                        :class="{ 'is-invalid': errors.first_registration }"
                    />
                    <label>{{ $t('auto.entry.first_registration') }}</label>
                    <ErrorMessage as="div" name="first_registration" class="invalid-feedback" />
                </div>
            </div>

            <div class="col-12 col-md-6 mt-3">
                <div class="form-floating">
                    <Field 
                        as="select"
                        class="form-control"
                        name="state"
                        :label="$t('auto.entry.state')"
                        rules="required"
                        :class="{ 'is-invalid': errors.state }"
                    >
                        <option value="">{{ $t('auto.entry.state') }} auswählen</option>
                        <option v-for="(o,k) in options.states" :key="k" :value="k">{{ o }}</option>
                    </Field>
                    <label>{{ $t('auto.entry.state') }}</label>
                    <ErrorMessage as="div" name="state" class="invalid-feedback" />
                </div>
            </div>

            <div class="col-12 mt-2 mt-sm-3 mt-md-2 mt-lg-3">
                <div class="form-floating">
                    <Field 
                        as="select"
                        class="form-control"
                        name="previous_owners"
                        
                        :label="$t('auto.entry.previous_owners')"
                        :class="{ 'is-invalid': errors.previous_owners }"
                    >
                        <option value="">{{ $t('auto.entry.previous_owners') }} auswählen</option>
                        <option v-for="(o,k) in options.previous_owners" :key="k" :value="k">{{ o }}</option>
                    </Field>
                    <label>{{ $t('auto.entry.previous_owners') }}</label>
                    <ErrorMessage as="div" name="previous_owners" class="invalid-feedback" />
                </div>
            </div>



            <p class="mt-4 mb-0">
                <strong>Antrieb / Motor*</strong>
            </p>

            <div class="col-12 col-md-6 mt-3">
                <div class="form-floating">
                    <Field 
                        as="select"
                        class="form-control"
                        name="fuel_type"
                        :label="$t('auto.entry.fuel_type')"
                        rules="required"
                        :class="{ 'is-invalid': errors.fuel_type }"
                    >
                        <option value="">{{ $t('auto.entry.fuel_type') }} auswählen</option>
                        <option v-for="(o,k) in options.fuel_type" :key="k" :value="k">{{ o }}</option>
                    </Field>
                    <label>{{ $t('auto.entry.fuel_type') }}</label>
                    <ErrorMessage as="div" name="fuel_type" class="invalid-feedback" />
                </div>
            </div>
            <div class="col-12 col-md-6 mt-3">
                <div class="form-floating">
                    <Field 
                        as="select"
                        class="form-control"
                        name="switching"
                        :label="$t('auto.entry.switching')"
                        rules="required"
                        :class="{ 'is-invalid': errors.switching }"
                    >
                        <option value="">{{ $t('auto.entry.switching') }} auswählen</option>
                        <option v-for="(o,k) in options.switching" :key="k" :value="k">{{ o }}</option>
                    </Field>
                    <label>{{ $t('auto.entry.switching') }}</label>
                    <ErrorMessage as="div" name="switching" class="invalid-feedback" />
                </div>
            </div>

            <div class="col-12 mt-2 mt-sm-3 mt-md-2 mt-lg-3">
                <div class="form-floating">
                    <Field 
                        as="select"
                        class="form-control"
                        name="drive"
                        :label="$t('auto.entry.drive')"
                        rules="required"
                        :class="{ 'is-invalid': errors.drive }"
                    >
                        <option value="">{{ $t('auto.entry.drive') }} auswählen</option>
                        <option v-for="(o,k) in options.drive" :key="k" :value="k">{{ o }}</option>
                    </Field>
                    <label>{{ $t('auto.entry.drive') }}</label>
                    <ErrorMessage as="div" name="drive" class="invalid-feedback" />
                </div>
            </div>

            <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 mt-2 mt-sm-3 mt-md-2 mt-lg-3 position-relative">
                <div class="form-floating">
                    <Field 
                        type="text"
                        class="form-control"
                        name="km"
                        @input="onChangeFormValues"
                        :label="$t('auto.entry.km')"
                        :placeholder="$t('auto.entry.km')"                        
                        rules="required|numeric"
                        :class="{ 'is-invalid': errors.km }"
                    />
                    <span class="input-info">km</span>
                    <label>{{ $t('auto.entry.km') }}</label>
                    <ErrorMessage as="div" name="km" class="invalid-feedback" />
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 mt-2 mt-sm-3 mt-md-2 mt-lg-3 position-relative">
                <div class="form-floating">
                    <Field 
                        type="text"
                        class="form-control"
                        name="ps"
                        :label="$t('auto.entry.ps')"
                        :placeholder="$t('auto.entry.ps')"                        
                        rules="numeric"
                        :class="{ 'is-invalid': errors.ps }"
                    />
                    <span class="input-info">PS</span>
                    <label>{{ $t('auto.entry.ps') }}</label>
                    <ErrorMessage as="div" name="ps" class="invalid-feedback" />
                </div>
            </div>

            <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 mt-2 mt-sm-3 mt-md-2 mt-lg-3 position-relative">
                <div class="form-floating">
                    <Field 
                        type="text"
                        class="form-control"
                        name="cubic_capacity"
                        :label="$t('auto.entry.cubic_capacity')"
                        :placeholder="$t('auto.entry.cubic_capacity')"                        
                        rules="numeric"
                        :class="{ 'is-invalid': errors.cubic_capacity }"
                    />
                    <span class="input-info">cm³</span>
                    <label>{{ $t('auto.entry.cubic_capacity') }}</label>
                    <ErrorMessage as="div" name="cubic_capacity" class="invalid-feedback" />
                </div>
            </div>


            <div class="col-12 position-relative">
                <label class="d-flex align-items-center mt-4">
                    <Field type="checkbox" name="driving_license_newcomers" :value="true" /><span>{{ $t('auto.entry.driving_license_newcomers') }}</span>
                </label>
            </div>
 
            <div class="col-12">
                <hr>
                <p class="mt-2 mb-3">
                    <strong>Inhalt der Anzeige*</strong>
                </p>

                <div class="form-floating">
                    <Field 
                        type="text" 
                        name="title" 
                        class="form-control"
                        @input="onChangeFormValues"
                        :class="{ 'is-invalid': errors.title }"
                        :label="$t('validation.attributes.title')"
                        placeholder="Titel der Anzeige einfügen*" 
                        rules="required"
                    />
                    <label>{{ $t('validation.attributes.title') }}</label>
                    <ErrorMessage as="div" name="title" class="invalid-feedback" />
                </div>

                <div class="form-floating mt-2 mt-sm-3 mt-md-2 mt-lg-3 ">
                    <Field 
                        as="textarea"
                        rows="7"
                        class="form-control" 
                        name="description" 
                        style="height: 200px;"
                        :class="{ 'is-invalid': errors.description }"
                        :label="$t('validation.attributes.description')"
                        placeholder="Beschreibung einfügen*"
                        rules="required"
                    />
                    <label>{{ $t('validation.attributes.description') }}</label>
                    <ErrorMessage as="div" name="description" class="invalid-feedback" />
                </div>
            </div>

            <div class="col-12">
                <hr>
                <p class="mt-0 mb-3">
                    <strong>Preis-Infos</strong>
                </p>

                <div class="position-relative">
                    <div class="form-floating">
                        <Field 
                            class="form-control"
                            type="text" 
                            name="price" 
                            @input="onChangeFormValues"
                            :class="{ 'is-invalid': errors.price }"
                            :label="$t('auto.entry.price')"
                            placeholder="Preis in (€)" 
                            rules="numeric"
                        />
                        <label>{{ $t('auto.entry.price') }} in €</label>
                        <span class="input-info">€</span>
                        <ErrorMessage as="div" name="price" class="invalid-feedback" />
                    </div>

                </div>


                
                <hr>
                <p class="mt-0 mb-2">
                    <strong>Insights - Ein Blick ins Innere</strong>
                </p>
                <p class="my-2 tincx-small text-muted">Bilder hochladen</p>
                <Field type="hidden" name="files" v-model="files" />
                <FileUpload 
                    ref="files"
                    v-model="files"
                    @files="onNewFiles"
                    accept=".jpg,.png,.jpeg"
                    :multiple="true"
                    :maxFiles="10"
                    :imageIcons="true"
                    :imagePreview="true"
                />


                <hr>
                <p class="mt-0 mb-2">
                    <strong>Ausstattung</strong>
                </p>


            </div>
            
            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3 mt-md-2 mt-lg-3">
                <div class="form-floating">
                    <Field 
                        as="select"
                        class="form-control"
                        name="form"
                        :label="$t('auto.entry.form')"
                        :class="{ 'is-invalid': errors.form }"
                    >
                        <option value="">{{ $t('auto.entry.form') }} auswählen</option>
                        <option v-for="(o,k) in options.form" :key="k" :value="k">{{ o }}</option>
                    </Field>
                    <label>{{ $t('auto.entry.form') }}</label>
                    <ErrorMessage as="div" name="form" class="invalid-feedback" />
                </div>
            </div>
        
            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3 mt-md-2 mt-lg-3">
                <div class="form-floating">
                    <Field 
                        as="select"
                        class="form-control"
                        name="doors"
                        :label="$t('auto.entry.doors')"
                        :class="{ 'is-invalid': errors.doors }"
                    >
                        <option value="">{{ $t('auto.entry.doors') }} auswählen</option>
                        <option v-for="(o,k) in options.doors" :key="k" :value="k">{{ o }}</option>
                    </Field>
                    <label>{{ $t('auto.entry.doors') }}</label>
                    <ErrorMessage as="div" name="doors" class="invalid-feedback" />
                </div>
            </div>


            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3 mt-md-2 mt-lg-3">
                <div class="form-floating">
                    <Field 
                        as="select"
                        class="form-control"
                        name="seatplaces"
                        :label="$t('auto.entry.seatplaces')"
                        :class="{ 'is-invalid': errors.seatplaces }"
                    >
                        <option value="">{{ $t('auto.entry.seatplaces') }} auswählen</option>
                        <option v-for="(o,k) in options.seatplaces" :key="k" :value="k">{{ o }}</option>
                    </Field>
                    <label>{{ $t('auto.entry.seatplaces') }}</label>
                    <ErrorMessage as="div" name="seatplaces" class="invalid-feedback" />
                </div>
            </div>

            
            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3 mt-md-2 mt-lg-3">
                <div class="form-floating">
                    <Field 
                        as="select"
                        class="form-control"
                        name="color"
                        :label="$t('auto.entry.color')"
                        :class="{ 'is-invalid': errors.color }"
                    >
                        <option value="">{{ $t('auto.entry.color') }} auswählen</option>
                        <option v-for="(o,k) in options.colors" :key="k" :value="k">{{ o }}</option>                        
                    </Field>
                    <label>{{ $t('auto.entry.color') }}</label>
                    <ErrorMessage as="div" name="color" class="invalid-feedback" />
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3 mt-md-2 mt-lg-3">
                <div class="form-floating">
                    <Field 
                        as="select"
                        class="form-control"
                        name="indoor_material"
                        :label="$t('auto.entry.indoor_material')"
                        :class="{ 'is-invalid': errors.indoor_material }"
                    >
                        <option value="">{{ $t('auto.entry.indoor_material') }} auswählen</option>
                        <option v-for="(o,k) in options.indoor_materials" :key="k" :value="k">{{ o }}</option>                        
                    </Field>
                    <label>{{ $t('auto.entry.indoor_material') }}</label>
                    <ErrorMessage as="div" name="indoor_material" class="invalid-feedback" />
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3 mt-md-2 mt-lg-3">
                <div class="form-floating">
                    <Field 
                        as="select"
                        class="form-control"
                        name="indoor_color"
                        :label="$t('auto.entry.indoor_color')"
                        :class="{ 'is-invalid': errors.indoor_color }"
                    >
                        <option value="">{{ $t('auto.entry.indoor_color') }} auswählen</option>
                        <option v-for="(o,k) in options.colors" :key="k" :value="k">{{ o }}</option>                        
                    </Field>
                    <label>{{ $t('auto.entry.indoor_color') }}</label>
                    <ErrorMessage as="div" name="indoor_color" class="invalid-feedback" />
                </div>
            </div>
        </div>


        <hr />

        <p class="my-2"><strong>Ausstattung</strong></p>

        <p class=" mt-0">Geben Sie hier verschiedene Ausstattungsmerkmale Ihres Autos ein.</p>


        <div id="tasks">
            <div class="d-flex flex-wrap mt-3 cloned-field" v-for="(equipmentName, equipmentIndex) in equipment" :key="equipmentName">
                <input v-model="equipment[equipmentIndex]" placeholder="Ausstattung eingeben" />
                <a href="#" @click.prevent="tasks.splice(equipmentIndex, 1)" class="ms-2 delete-field d-flex align-items-center justify-content-center">
                    <svg class="text-primary" width="17" height="21.8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 21.8">
                        <path fill="currentcolor" d="M1.2,19.4c0,1.3,1.1,2.4,2.4,2.4h9.7c1.3,0,2.4-1.1,2.4-2.4V4.8H1.2v14.5Z"></path>
                        <polygon fill="currentcolor" points="12.7 1.2 11.5 0 5.4 0 4.2 1.2 0 1.2 0 3.6 17 3.6 17 1.2 12.7 1.2"></polygon>
                    </svg>
                </a>
            </div>
            
            <div class="d-flex flex-wrap mt-3 original-field">
                <input @keypress.enter.prevent="onEquipmentEnter" @change="onEquipmentEnter" placeholder="Ausstattung eingeben" />
                <a href="#" class="btn btn-primary text-nowrap ms-2 d-flex align-items-center px-3">+ Hinzufügen</a>
            </div>
        </div>

        <hr>
        <button 
            :class="{ disabled: Object.keys(errors).length || !meta.valid }"
            type="submit" class="ms-auto d-flex align-items-center justify-content-center mt-4 btn btn-primary btn-large px-3 btn-te" 
        > 
                {{ editId ? 'Anzeige speichern' : 'Anzeige veröffentlichen' }}
                <svg class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                    <path fill="#FFFFFF" d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
            C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"></path>
                    <path fill="#FFFFFF" d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
            c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
            c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"></path>
                </svg>
        </button>
    </Form>
</template>

<script>
import axios from 'axios'
//import emitter from '../plugins/mitt'
import Swal from 'sweetalert2'
import { Form, Field, ErrorMessage } from 'vee-validate'
import MapForm from "@/components/inc/form/MapForm.vue"
import FileUpload from "@/components/inc/form/FileUpload.vue"
import OffersPreview from '@/components/offers/inc/Preview.vue'
import emitter from '../../../plugins/mitt'

export default {
    name: 'FormAuto',
    components: {
        Form,
        Field,
        ErrorMessage,
        MapForm,
        FileUpload,
        OffersPreview
    },
    inject: ['$t'],
    mounted: async function () {
        let parent = document.getElementById('v-offers-form-auto')
        if (parent && parent.dataset.id) {
            this.editId = parent.dataset.id
        }
        this.loadData()
    },
    computed: {
        offer: function() {
            return {
                ...this.$refs.form.values,
            }
        },
    },
    watch: {

    },
    methods: {
        onPreviewClick: function() {
            if(this.active && this.options && this.options.viewUrl) {
                window.open(this.options.viewUrl, '_blank')
            }
        },
        onEquipmentEnter: function(event) {
            this.equipment.push(event.target.value)
            event.target.value=''
        },
        onNewFiles: function() {
            setTimeout(() => {
                this.onChangeFormValues()
            }, 100)
        },

        onTitleUpdate: function() {
            var values = this.$refs.form.values
            if(!values.brand || !values.modelname) return
            var brandName = this.options.brands.find(b => b.id==values.brand).name
            this.$refs.form.setFieldValue('title', `${brandName} ${values.modelname}`)
            this.onChangeFormValues()
        },

        onChangeFormValues: function() {
            if(this.$refs.form) {
                console.log('onChangeFormValues FormFloh', this.$refs.form.values)
                this.formValues = {...this.$refs.form.values}
            } else {
                this.formValues = null
            }
            this.$emit('formValues', {...this.formValues, customer: this.customer})
        },

        onInvalid: function ({ values, errors, results }) {
            let html =
                '<ul>' +
                Object.values(errors)
                    .map((e) => '<li>' + e + '</li>')
                    .join('') +
                '</ul>'
            Swal.fire({
                title: this.$t('generic.error'),
                html: html,
                icon: 'error',
            })
        },
        onSubmit: function (values) {
            Swal.fire({
                title: this.$t('popup.register.title'),
                text: this.$t('generic.loading'),
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            })

            const data = {
                ...this.$refs.form.values,
                equipment: this.equipment,
            }
            axios
                .post(`/api/profile/offers/${this.type}/create${this.editId ? '/'+this.editId : ''}`, data)
                .then((response) => {
                    console.log(response)
                    if (response.data.error) {
                        Swal.fire(this.$t('generic.error'), response.data.error, 'error')
                        return
                    }

                    if(this.editId) {
                        Swal.fire({
                            icon: 'success',
                            text: $t('generic.save_alert'),
                            didClose: () => window.scrollTo(0,0)
                        })
                        this.loadData();
                        emitter.emit('profile_reload_offer_list', true)
                        return;
                    }

                    Swal.close()
                    this.$emit('success', response.data)
                })
                .catch((error) => {
                    console.log(error)
                    if (error.status == 422) {
                        var errors = error.response.data.errors
                        var errorBag = {}
                        for (var field in errors) {
                            errorBag[field] = errors[field].join(', ')
                        }
                        this.$refs.form.setErrors(errorBag)

                        let html =
                            '<ul>' +
                            Object.values(errorBag)
                                .map((e) => '<li>' + e + '</li>')
                                .join('') +
                            '</ul>'
                        Swal.fire({
                            title: this.$t('generic.error'),
                            icon: 'error',
                            html: html,
                        })
                        return
                    }
                    Swal.fire(this.$t('generic.error'), this.$t('generic.error_try_again_later'), 'error')
                })
        },
        loadData: function () {
            if (this.loading) return
            this.loading = true
            var editUrlPostfix = ''
            if(this.editId) {
                editUrlPostfix = '/'+this.editId
            }
            axios
                .get(`/api/profile/offers/${this.type}/create${editUrlPostfix}`)
                .then(async (response) => {
                    this.loading = false

                    this.options = {...response.data.options}
                    this.profileRedirectInfo = response.data.profileRedirectInfo
                    let formData = response.data.formData
                    this.customer = response.data.customer
                    this.equipment = formData.equipment || []

                    setTimeout(() => {
                        if (this.$refs && this.$refs.form) {
                            this.$refs.form.resetForm({
                                values: formData
                            })

                            this.onChangeFormValues()
                        }
                    }, 100)
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error)
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },
    },
    data() {
        return {
            editId: null,
            active: true,
            type: 'auto',
            profileRedirectInfo: null,
            customer: null,
            success: false,
            files: [],
            equipment: [],
            options: {},
            formValues: null,
        }
    },
}
</script>