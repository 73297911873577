<template>
    <Form
        ref="form"
        class="form check-required-fields"
        novalidate
        @change="onChangeFormValues"
        @submit="onSubmit"
        @invalid-submit="onInvalid"
        v-slot="{ errors, values, meta }"
    >
        <div>
            <h4 class="my-2">Du möchtest JOB-Angebote erhalten?</h4>
            <p class="my-2">Um neue Jobangebote zu erhalten, kannst du hier ganz einfach dein Job-Profil aktiveren und bestimmen ob du öffentlich oder diskret als Kandidat für Unternehmen sichtbar bist.</p>
            <label class="d-flex align-items-center mt-2 mt-sm-1">
                <input type="checkbox" name="active" v-model="active" /><span>Ja, ich möchte Job-Angebote erhalten</span>
            </label>
        </div>
        <div v-if="loading" class="mt-3">
            <div class="loader loader-sm"></div>
        </div>
        <template v-if="!active && !loading">
            <hr>
            <div class="alert alert-warning">
                Dein Job-Profil ist inaktiv, du erhältst keine aktiven Anfragen von uns.
            </div>
        </template>
        <div v-show="active">
            <hr>
            <div class="visibility-container">
                <div class="d-flex align-items-center switch-box">
                    <p class="anonym" :class="{active: !public}">Diskret</p>
                    <input class="form-check-input mx-2" name="public" v-model="public" readonly @change="onPublicSwitchChange" type="checkbox" role="switch" />
                    <p class="public" :class="{active: public}">Öffentlich</p>
                </div>
                <small class="mt-2 p-2 info-message anonym" :class="{active: !public}">Deine aktuelle Einstellung: <strong>Diskret</strong><br><br>
                    Du entscheidest pro Job-Anfrage, wann du deine persönlichen Daten weitergeben möchtest.     
                </small>
                <small class="mt-2 p-2 info-message public" :class="{active: public}">Deine aktuelle Einstellung:
                    <strong>Öffentlich</strong><br><br>
                    Unternehmen sehen nun dein Profil inklusive deiner persönlicher Kontaktdaten.</small>
            </div>
            
            <div 
                id="anonym"
                :class="{'d-none': switchPublicPopup != 'anonym'}"
                class="popup-container d-flex align-items-center justify-content-center text-box"
            >
                <div class="popup-box bg-white p-4 p-lg-5 position-relative">
                    <p class="my-0 tincx-h4">Du bist dabei, die Sichtbarkeit deines Profils auf
                        diskret zu stellen.</p>
                    <p class="mt-2">Alle deine persönlichen Daten und Informationen werden für
                        Arbeitgeber nun unsichtbar.
                        Diese Einstellung kann jederzeit in deinem Account wieder geändert
                        werden.</p>
                    <a href="#" class="btn btn-general btn-be accept" @click.prevent="switchPublicPopup = null;public=false">Ok, alles klar</a>
                    <a href="#" class="btn btn-white btn-bs ms-2 reject" @click.prevent="switchPublicPopup = null">Abbrechen</a>
                </div>
            </div>

            <div 
                id="public"    
                :class="{'d-none': switchPublicPopup != 'public'}"
                class="popup-container d-flex align-items-center justify-content-center text-box"
            >
                <div class="popup-box bg-white p-4 p-lg-5 position-relative">
                    <p class="my-0 tincx-h4">Du bist dabei, die Sichtbarkeit deines Profils auf
                        öffentlich zu stellen.</p>
                    <p class="mt-2">Ab jetzt wird dein Profil mit deinen persönlichen Angaben
                        und von dir bereitgestellten
                        Informationen für Arbeitgeber sichtbar.
                        Diese Einstellung kann jederzeit in deinem Account wieder geändert
                        werden.</p>
                    <a href="#" class="btn btn-general btn-be accept" @click.prevent="switchPublicPopup = null;public=true">Ok, alles klar</a>
                    <a href="#" class="btn btn-white btn-bs ms-2 reject" @click.prevent="switchPublicPopup = null">Abbrechen</a>
                </div>
            </div>

            <hr>
            
            <template v-if="true">
                <span class="badge response-agency_top">Tipp</span>
                <h4 class="my-2">Entfalte dein Potenzial. Hebe dich ab. Werde entdeckt.</h4>
                <p class="mb-1 mt-0">Halte dein Profil aktuell! <br>Je vollständiger und
                    aussagekräftiger dein Profil ist, desto bessere Jobvorschläge und -einladungen
                    erhältst du.</p>
                <small class="text-muted">Die mit * markierten Felder sind Pflichtfelder.</small>

            </template>

            <div class="steps-box">
                <p><strong>{{ filloutPercentage }}%</strong> deines Unternehmensprofils ist ausgefüllt</p>
                <span class="steps d-block bg-light">
                        <span class="active-step d-block bg-black" :style="{width: `${filloutPercentage}%`}"></span>
                    </span>
            </div>

            <hr id="preferences">

            <Rating
                :title="preferences.title"
                :text="preferences.text"
                :preferences="preferences.list"
                pagetype="profile"
                :showAllSteps="true"
                :requiredInfo="false"
                v-model="selectedUserRatings"
            />

            <hr>
            <h4 class="my-2">Dein beruflicher Hintergrund</h4>

            <p class="my-2"><strong>Lade deinen Lebenslauf hoch</strong></p>

            <FileUpload 
                ref="fileUpload"
                v-model="files"
                accept=".pdf"
                :multiple="false"
            />

            <hr>
            <p class="my-2"><strong>Wähle deinen Jobtitel / Beruf*</strong></p>
            <Field 
                type="text" 
                name="title"
                placeholder="z.B. Marketing Manager oder Frontend Developer"
                rules="required"
                label="Jobtitel / Beruf"
                :class="{ 'is-invalid': errors.title }"
            />
            <ErrorMessage as="div" name="title" class="invalid-feedback" />


            <hr>
            <p class="my-2"><strong>Absolvierte Ausbildung</strong></p>
            <Field as="select" name="education">
                <option value="" selected disabled>Ausbildung auswählen</option>
                <option v-for="(v,k) in options.education" :key="k" :value="k">{{ v }}</option>
            </Field>

            <hr>
            <p class="my-2"><strong>Bevorzugter Arbeitsort</strong></p>
            <p class="my-2">Es können auch mehrere Orte angegeben werden.</p>

            <Field 
                type="hidden" 
                name="preferredWorkplaces_validation"
                label="Bevorzugter Arbeitsort"
                :rules="selectedUserRatings.location > 0 ? 'required' : ''"
            />
            <ErrorMessage as="div" name="preferredWorkplaces_validation" class="invalid-feedback d-block" />            

            <div>
                <div class="d-flex flex-wrap mt-2 mt-sm-3 cloned-field" v-for="(item, index) in preferredWorkplaces" :key="index">
                    <select v-model="preferredWorkplaces[index]">
                        <option :value="null" selected disabled>Arbeitsort auswählen</option>
                        <optgroup v-for="(places,k) in options.places" :key="k" :label="k">
                            <option v-for="place in places" :key="place.id" :value="place.id">{{ place.name }}</option>
                        </optgroup>
                    </select>
                    <a href="#"
                        @click.prevent="preferredWorkplaces.splice(index, 1)"
                        class="ms-2 delete-field d-flex align-items-center justify-content-center">
                        <svg class="text-primary" width="17" height="21.8"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 21.8">
                            <path fill="currentcolor"
                                    d="M1.2,19.4c0,1.3,1.1,2.4,2.4,2.4h9.7c1.3,0,2.4-1.1,2.4-2.4V4.8H1.2v14.5Z"/>
                            <polygon fill="currentcolor"
                                        points="12.7 1.2 11.5 0 5.4 0 4.2 1.2 0 1.2 0 3.6 17 3.6 17 1.2 12.7 1.2"/>
                        </svg>
                    </a>
                </div>

                <div class="d-flex flex-wrap mt-2 mt-sm-3 original-field">
                    <select @change="onAdd($event, preferredWorkplaces)">
                        <option value="" selected disabled>Arbeitsort auswählen</option>
                        <optgroup v-for="(places,k) in options.places" :key="k" :label="k">
                            <option v-for="place in places" :key="place.id" :value="place.id">{{ place.name }}</option>
                        </optgroup>
                    </select>
                </div>
            </div>

            <hr>
            <p class="my-2"><strong>Gehaltsvorstellung netto (monatlich)</strong></p>

            <div class="row-small mt-3">
                <div class="col-6 col-sm-4">
                    <Field rules="numeric" type="text" name="salary_min_value" placeholder="Von" />
                </div>
                <div class="col-6 col-sm-4">
                    <Field rules="numeric" type="text" name="salary_max_value" placeholder="Bis" />
                </div>
            </div>

            <hr>
            <p class="my-2"><strong>Arbeitszeitmodell</strong></p>
            <p class="my-2">Du kannst auch mehrere Optionen wählen.</p>

            <label class="d-flex align-items-center mt-2 mt-sm-1" v-for="(v, k) in options.types">
                <Field type="checkbox" name="types" :value="k" /><span>{{  v }}</span>
            </label>

            <hr>
            <p class="my-2"><strong>Home-Office-Optionen</strong></p>
            <p class="my-2">Du kannst auch mehrere Optionen wählen.</p>

            <label class="d-flex align-items-center mt-2 mt-sm-1" v-for="(v, k) in options.home_office">
                <Field type="checkbox" name="home_office" :value="k" /><span>{{  v }}</span>
            </label>

            <hr>
            <p class="my-2"><strong>Bevorzugte Branchen</strong></p>
            <p class="my-2">Du kannst auch mehrere Branchen wählen.</p>

            <label class="d-flex align-items-center mt-2 mt-sm-1" v-for="(v, k) in options.categories">
                <Field type="checkbox" name="categories" :value="k" /><span>{{ v }}</span>
            </label>


            <hr>
            <p class="my-2"><strong>Sprachkenntnisse</strong></p>
            <p class="my-2">Du kannst auch mehrere Optionen wählen.</p>

            <label class="d-flex align-items-center mt-2 mt-sm-1" v-for="(v, k) in options.languages">
                <Field type="checkbox" name="languages" :value="k" /><span>{{  v }}</span>
            </label>




            <div class="steps-box">
                <p><strong>{{ filloutPercentage }}%</strong> deines Profils ist ausgefüllt</p>
                <span class="steps d-block bg-light">
                        <span class="active-step d-block bg-black" :style="{width: `${filloutPercentage}%`}"></span>
                    </span>
            </div>
        </div>


        <button type="submit"
                    :class="{ disabled: Object.keys(errors).length || !meta.valid }"
                    class="ms-auto d-flex align-items-center justify-content-center mt-4 btn btn-primary btn-large px-3 btn-te"
            >
                    {{ $t('fe.generic.save_changes')}}
                <svg class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20.8 20.8">
                    <path fill="#FFFFFF" d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
            C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"></path>
                    <path fill="#FFFFFF" d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
            c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
            c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"></path>
                </svg>
            </button>

    </Form>
</template>
<script>
import {ref} from 'vue'
import axios from 'axios'
import emitter from '../plugins/mitt'
import Swal from 'sweetalert2'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { useDropzone } from "vue3-dropzone";
import FileUpload from './inc/form/FileUpload.vue'
import Rating from './inc/form/Rating.vue'

export default {
    name: 'ProfileJobSettings',
    components: {
        Form,
        Field,
        ErrorMessage,
        FileUpload,
        Rating
    },
    inject: ['$t'],
    mounted: function () {
        this.loadData()
        this.ref
    },
    watch: {
        active: {
            handler(val) {
                //console.log('active = '+val)
                emitter.emit('user_job_profile', val)
            },
        },
        preferredWorkplaces: {
            handler(val) {
                let _new = [...new Set(this.preferredWorkplaces)]
                if(this.preferredWorkplaces.length != _new.length) {
                    this.preferredWorkplaces = _new
                }
                if(this.$refs.form) {
                    this.$refs.form.setFieldValue('preferredWorkplaces_validation', this.preferredWorkplaces)
                }
            },
            deep: true,
        },
        selectedUserRatings: {
            handler(val) {
                emitter.emit('selectedUserRatings_preferences', val)
            },
            deep: true
        }
    },
    computed: {
        preferences() {
            //todo: copied from JobAdvisor.vue, we need to refactor this ones
            return {
                title: this.$t('fe.job_advisor.preferences.title'),
                text: this.$t('fe.job_advisor.preferences.text'),
                // list: this.$t('fe.job_advisor.preferences.list'), // TODO should return a Array?!
                list: [
                    { title: '1. Wie wichtig ist dir das Gehalt?', name: 'money' },
                    { title: '2. Wie wichtig sind dir Karrierechancen?', name: 'career' },
                    { title: '3. Wie wichtig sind dir flexible Arbeitszeiten oder Home Office?', name: 'homeoffice' },
                    { title: '4. Wie wichtig ist dir, dass Dein Arbeitsort nah bei Deinem Wohnort liegt?', name: 'location' },
                ],
            }
        },
        filloutPercentage: function() {
            var countSteps = 10;
            var computedSteps = 0;
            if(this.files.length) {
                computedSteps++
            }
            if(Object.keys(this.selectedUserRatings).length==4) {
                computedSteps++
            }

            if(this.preferredWorkplaces.length) {
                computedSteps++
            }

            if(this.formValues) {
                var changeTriggers = ['title', 'education', 'types', 'home_office', 'categories', 'languages']
                changeTriggers.forEach(k => {
                    if(this.formValues[k] && this.formValues[k].length) {
                        computedSteps++
                    }
                })
                if(this.formValues.salary_min_value && this.formValues.salary_max_value) {
                    computedSteps++
                }
            }

            //countSteps :  computedSteps = 100 : x
            return Math.round(computedSteps * 100 / countSteps)
        }
    },
    methods: {
        onAdd: function(e, arr) {
            if(!e.target.value) return
            arr.push(e.target.value)
            e.target.value = ""
        },
        onChangeFormValues: function() {
            if(this.$refs.form) {
                console.log(this.$refs.form.values)
                this.formValues = this.$refs.form.values
            } else {
                this.formValues = null
            }
        },
        onPublicSwitchChange: function(event) {
            console.log({event: event, public: this.public})
            this.public = !this.public
            if(this.public) {
                this.switchPublicPopup = 'anonym'
            } else {
                this.switchPublicPopup = 'public'
            }
        },
        loadData: function () {
            if (this.loading) return
            this.loading = true
            axios
                .get('/api/profile/job-settings')
                .then((response) => {
                    this.loading = false

                    this.options = response.data.options


                    
                    if(response.data.data) {
                        let d = response.data.data
                        this.active = d.active
                        this.public = d.public
                        if(d.selectedUserRatings) {
                            this.selectedUserRatings = d.selectedUserRatings
                        }
                        if(d.form.preferredWorkplaces) {
                            this.preferredWorkplaces = [...d.form.preferredWorkplaces]
                        }
                        if (this.$refs && this.$refs.form) {
                            this.$refs.form.setValues(response.data.data.form)
                            this.formValues = response.data.data.form
                        }
                    }
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error)
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },
        onInvalid: function ({ values, errors, results }) {
            let html =
                '<ul>' +
                Object.values(errors)
                    .map((e) => '<li>' + e + '</li>')
                    .join('') +
                '</ul>'
            Swal.fire({
                title: this.$t('generic.error'),
                html: html,
                icon: 'error',
            })
        },
        onSubmit: function (values) {
            Swal.fire({
                text: this.$t('generic.loading'),
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            })

            const selectedUserRatings = this.selectedUserRatings
            const active = this.active
            const files = this.files
            const preferredWorkplaces = this.preferredWorkplaces
            axios
                .post(
                    '/api/profile/job-settings',
                    {
                        ...values,
                        active,
                        public: this.public,
                        selectedUserRatings,
                        files,
                        preferredWorkplaces
                    }
                )
                .then((response) => {
                    console.log(response)
                    if (response.data.error) {
                        Swal.fire(this.$t('generic.error'), response.data.error, 'error')
                        return
                    }

                    //Swal.close()
                    Swal.fire({
                        icon: 'success',
                        text: $t('generic.save_alert'),
                        didClose: () => window.scrollTo(0,0)
                    })


                    this.loadData()
                })
                .catch((error) => {
                    console.log(error)
                    if (error.status == 422) {
                        var errors = error.response.data.errors
                        var errorBag = {}
                        for (var field in errors) {
                            errorBag[field] = errors[field].join(', ')
                        }
                        this.$refs.form.setErrors(errorBag)

                        let html =
                            '<ul>' +
                            Object.values(errorBag)
                                .map((e) => '<li>' + e + '</li>')
                                .join('') +
                            '</ul>'
                        Swal.fire({
                            title: this.$t('generic.error'),
                            icon: 'error',
                            html: html,
                        })
                        return
                    }
                    Swal.fire(this.$t('generic.error'), this.$t('generic.error_try_again_later'), 'error')
                })
        },
    },
    data() {
        return {
            active: false,
            public: false,
            selectedUserRatings: {},
            files: [],
            preferredWorkplaces: [],
            formValues: null,

            switchPublicPopup: null,
            options: {},
        }
    },
}
</script>