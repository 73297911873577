<template>
    <Form 
        ref="form"
        class="form check-required-fields edit-agency"
        novalidate
        @change="onChangeFormValues"
        @submit="onSubmit"
        @invalid-submit="onInvalid"
        v-slot="{ errors, values, meta }"
    >
        <Field type="hidden" name="activeDirty" />

        <div>
            <h4 class="my-2">Unternehmensseite</h4>
            <p class="my-2">Hier kannst du die Inhalte der Unternehmensseite definieren. Mit einem Firmenprofil erhöhen Sie die Präsenz und Sichtbarkeit ihrer Firma auf unserer Seite.</p>
            <label class="d-flex align-items-center mt-2 mt-sm-1 mb-2">
                <input type="checkbox" name="active" v-model="active" /><span>Unternehmensprofil aktivieren und öffentlich sichtbar machen</span>
            </label>
            <small class="text-muted" v-if="active">Mit dieser Einstellung wird das Unternehmensprofil in der Liste "Unternehmen" ausgegeben, sowie bei aktiven Anzeigen verlinkt.</small>
            <small class="text-muted" v-else>Mit dieser Einstellung erscheint das Unternehmensprofil nicht in der Liste "Unternehmen" und wird auch nicht bei aktiven Anzeigen verlinkt.</small>
        </div>

        <hr>

        <div class="steps-box">
                <p><strong>{{ filloutPercentage }}%</strong> deines Profils ist ausgefüllt</p>
                <span class="steps d-block bg-light">
                        <span class="active-step d-block bg-black" :style="{width: `${filloutPercentage}%`}"></span>
                    </span>
        </div>

        <div :class="{'o-disabled': !active}">
            <p class="mt-3 mb-2">
                <strong>Titelbild & Logo</strong>
            </p>

            <Field
                type="hidden"
                name="logo"
                label="Logo"
                rules="required"
            />
            <ErrorMessage
                v-if="formHasBeenSent"
                as="div"
                name="logo"
                class="invalid-feedback d-block mb-2"
            />

            <Field
                type="hidden"
                name="cover"
                label="Cover-Bild"
                rules="required"
            />
            <ErrorMessage
                v-if="formHasBeenSent"
                as="div"
                name="cover"
                class="invalid-feedback d-block mb-2"
            />

            <div class="position-relative edit-agency-header box-unternehmen pb-4">
                <ImageUploadContainer
                    class="cover-box"
                    :modelValue="values.cover"
                    @update:modelValue="(file) => $refs.form.setFieldValue('cover', {...file})"
                >
                    <template v-slot:edit>
                        <label class="position-absolute edit d-flex align-items-center justify-content-center bg-light p-2 edit-title-image" data-tooltip="Titelbild bearbeiten">
                            <img src="/images/icons/icon-edit.svg" alt="Icon Edit">
                        </label>
                    </template>
                    <template v-slot:logo="{ logo }">
                        <img v-if="logo" class="titel-bild" :src="logo" alt="Cover">
                        <div v-else class="titel-bild">COVER-BILD</div>
                    </template>
                </ImageUploadContainer>

                <ImageUploadContainer
                    class="logo-box"
                    :modelValue="values.logo"
                    @update:modelValue="(file) => $refs.form.setFieldValue('logo', {...file})"
                >
                    <template v-slot:edit>
                        <label class="position-absolute edit d-flex align-items-center justify-content-center bg-light p-2 edit-logo" data-tooltip="Logo bearbeiten">
                            <img src="/images/icons/icon-edit.svg" class="edit-icon" alt="Icon Edit">
                        </label>
                    </template>
                    <template v-slot:logo="{ logo }">
                        <img v-if="logo" class="logo-bild" :src="logo" alt="Logo">
                        <div v-else class="logo-bild">LOGO</div>
                    </template>
                </ImageUploadContainer>
            </div>
            <p class="mt-3 mb-2">
                <strong>Name des Unternehmens</strong>
            </p>

            <Field 
                type="text" 
                name="title"
                placeholder="Name des Unternehmens einfügen"
                label="Name des Unternehmens"
                rules="required"
                :class="{ 'is-invalid': errors.title }"
            />
            <ErrorMessage as="div" name="title" class="invalid-feedback" />


            <p class="mt-3 mb-2">
                <strong>Untertitel</strong>
            </p>


            <Field 
                type="text" 
                name="subtitle"
                placeholder="Untertitel einfügen"
                rules=""
                :class="{ 'is-invalid': errors.subtitle }"
            />
            <ErrorMessage as="div" name="subtitle" class="invalid-feedback" />


            
            <p class="mt-3 mb-2">
                <strong>Über uns</strong>
            </p>
            <p class="my-2 text-muted">Bitte beschreiben Sie das Unternehmen</p>


            <Field 
                as="textarea"
                name="content"
                placeholder="Beschreibung*"
                rules="required"
                rows="7"
                class="mt-1"
                :class="{ 'is-invalid': errors.content }"
            />
            <ErrorMessage as="div" name="content" class="invalid-feedback" />

            <hr>
            <p class="my-2">
                <strong>Steckbrief</strong>
            </p>
            <p class="my-2">Wähle aus, welche Infos in deinem Unternehmensprofil angezeigt werden sollen</p>
            <div class="agency-infos">
                <div class="row-small align-items-center my-3 my-xs-2 my-md-3 my-lg-2" :class="{'o-disabled': !values.show_workers}">
                    <label class="d-flex align-items-center col-12 col-xs-6 col-xl-3">
                        <Field type="checkbox" name="show_workers" :value="true" />
                        <div class="icon-box">
                            <img src="/images/icons/icon-mitarbeiter-large.svg" width="27" height="27">
                        </div>
                        <span class="text-muted">Mitarbeiter</span>
                    </label>
                    <div class="col-12 col-xs-6 col-md-12 col-lg-6 col-xl mt-2 mt-lg-0">
                        <Field type="text" name="workers" placeholder="Anzahl einfügen" rules="required" />
                    </div>
                </div>
                <div class="row-small align-items-center my-3 my-xs-2" :class="{'o-disabled': !values.show_place}">
                    <label class="d-flex align-items-center col-12 col-xs-6 col-xl-3">
                        <Field type="checkbox" name="show_place" :value="true" />

                        <div class="icon-box">
                            <img src="/images/icons/icon-location-large.svg" width="25" height="30">
                        </div>
                        <span class="text-muted">Hauptsitz</span>
                    </label>
                    <div class="col-12 col-lg align-items-center d-flex mt-2 mt-xl-0">
                        <Field 
                            type="text" 
                            name="show_place_maps_url" 
                            placeholder="Google Maps Link" />
                        <span class="ms-2" data-tooltip="Den Link finden Sie auf Google Maps unter Teilen - &quot;Link zum Teilen&quot;. Fügen Sie anschließend diesen Link hier ein.">
                            <img width="25" height="25" src="/images/icons/icon-info.svg" alt="Icon info">
                        </span>
                    </div>
                </div>
                <label class="d-flex align-items-center mt-3" :class="{'o-disabled': !values.show_locations}">
                    <Field type="checkbox" name="show_locations" :value="true" />
                    <div class="icon-box">
                        <img src="/images/icons/icon-haus-large.svg" width="37" height="30">
                    </div>
                    <span class="text-muted">Standorte</span>
                </label>
                <label class="d-flex align-items-center mt-4" :class="{'o-disabled': !values.show_jobs}">
                    <Field type="checkbox" name="show_jobs" :value="true" />
                    <div class="icon-box">
                        <img src="/images/icons/icon-rakete-large.svg" width="30" height="30">
                    </div>
                    <span class="text-muted">Offene Jobs</span>
                </label>
            </div>
            <hr>
            <p class="my-2">
                <strong>Mitarbeitervorteile</strong>
            </p>
            <div class="row">
                <label class="d-flex align-items-center mt-2 mt-sm-1 col-12 col-lg-6" v-for="(v) in options.benefits" :key="v.id">
                    <Field type="checkbox" name="benefits" :value="v.id" /><span>{{  v.name }}</span>
                </label>
            </div>
            <hr>
            <p class="my-2">
                <strong>Insights - Ein Blick ins Innere</strong>
            </p>
            <p class="mt-3 mb-2 tincx-small text-muted">Bilder</p>

            <FileUpload 
                ref="fileUploadImages"
                v-model="files"
                accept=".jpg,.png,.jpeg"
                :multiple="true"
                :maxFiles="10"
                :imageIcons="true"
                :imagePreview="true"
            />

            <p class="mt-3 mb-2 tincx-small text-muted">Video</p>
            
            <Field 
                type="text" 
                name="video_url"
                placeholder="Vimeo-URL oder Youtube-URL einfügen"
                rules="url"
                :class="{ 'is-invalid': errors.video_url }"
            />
            <ErrorMessage as="div" name="video_url" class="invalid-feedback" />

            <hr>
            <p class="my-2">
                <strong>Branche</strong>
            </p>

            <Field as="select" rules="required" label="Branche" name="maincategory" v-model="mainCategory">
                <option value="" selected disabled>Branche auswählen</option>
                <option v-for="(v,k) in options.categories" :key="k" :value="k">{{ v }}</option>
            </Field>

            <p class="my-2 mt-3">
                <strong>Weitere Branchen</strong><br>
                <span class="text-muted">Falls zutreffend kannst du hier weitere Branchen auswählen, welche für Unternehmen relevant sind.</span>
            </p>


            <template  v-for="(v, k) in options.categories">
                <label :key="k" v-if="k!=mainCategory" class="d-flex align-items-center mt-2 mt-sm-1">
                    <Field type="checkbox" name="subcategories" :value="k" /><span>{{ v }}</span>
                </label>
            </template>

            <hr>
            <p class="my-2">
                <strong>Social Media</strong>
            </p>

            <ErrorMessage as="div" name="social_url_facebook" class="invalid-feedback d-block" />
            <ErrorMessage as="div" name="social_url_x" class="invalid-feedback d-block" />
            <ErrorMessage as="div" name="social_url_instagram" class="invalid-feedback d-block" />
            <ErrorMessage as="div" name="social_url_whatsapp" class="invalid-feedback d-block" />
            <ErrorMessage as="div" name="social_url_pinterest" class="invalid-feedback d-block" />
            <ErrorMessage as="div" name="social_url_youtube" class="invalid-feedback d-block" />
            <ErrorMessage as="div" name="social_url_linkedin" class="invalid-feedback d-block" />
            <ErrorMessage as="div" name="social_url_tiktok" class="invalid-feedback d-block" />


            <div class="agency-social-media row">
                <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center mt-2">
                    <div class="icon-box">
                        <img src="/images/icons/icon-facebook.svg">
                    </div>
                    <Field 
                        type="text" 
                        name="social_url_facebook"
                        placeholder="Link einfügen"
                        rules="url"
                        :class="{ 'is-invalid': errors.social_url_facebook }"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center mt-2">
                    <div class="icon-box">
                        <img src="/images/icons/icon-x.svg">
                    </div>

                    <Field 
                        type="text" 
                        name="social_url_x"
                        placeholder="Link einfügen"
                        rules="url"
                        :class="{ 'is-invalid': errors.social_url_x }"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center mt-2">
                    <div class="icon-box">
                        <img src="/images/icons/icon-instagram.svg">
                    </div>

                    <Field 
                        type="text" 
                        name="social_url_instagram"
                        placeholder="Link einfügen"
                        rules="url"
                        :class="{ 'is-invalid': errors.social_url_instagram }"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center mt-2">
                    <div class="icon-box">
                        <img src="/images/icons/icon-whatsapp.svg">
                    </div>

                    <Field 
                        type="text" 
                        name="social_url_whatsapp"
                        placeholder="Link einfügen"
                        rules="url"
                        :class="{ 'is-invalid': errors.social_url_whatsapp }"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center mt-2">
                    <div class="icon-box">
                        <img src="/images/icons/icon-tiktok.svg">
                    </div>

                    <Field 
                        type="text" 
                        name="social_url_tiktok"
                        placeholder="Link einfügen"
                        rules="url"
                        :class="{ 'is-invalid': errors.social_url_tiktok }"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center mt-2">
                    <div class="icon-box">
                        <img src="/images/icons/icon-youtube.svg">
                    </div>

                    <Field 
                        type="text" 
                        name="social_url_youtube"
                        placeholder="Link einfügen"
                        rules="url"
                        :class="{ 'is-invalid': errors.social_url_youtube }"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center mt-2">
                    <div class="icon-box">
                        <img src="/images/icons/icon-linkedin.svg">
                    </div>

                    <Field 
                        type="text" 
                        name="social_url_linkedin"
                        placeholder="Link einfügen"
                        rules="url"
                        :class="{ 'is-invalid': errors.social_url_linkedin }"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-12 col-lg-6 d-flex align-items-center mt-2">
                    <div class="icon-box">
                        <img src="/images/icons/icon-pinterest.svg">
                    </div>

                    <Field 
                        type="text" 
                        name="social_url_pinterest"
                        placeholder="Link einfügen"
                        rules="url"
                        :class="{ 'is-invalid': errors.social_url_pinterest }"
                    />
                </div>

            </div>
            <hr>
            <p class="my-2">
                <strong>Standorte &amp; Kontakte ({{ locations.length }})</strong>
            </p>

            <p>Hier können Sie Standorte angeben, welche im Unternehmensprofil ausgegeben werden.</p>

            <div class="mt-3 mt-sm-4 alert alert-warning" v-if="locations.length==0">
                    Noch keine Standorte vorhanden. Klicke auf "Hinzufügen" um den ersten Standort hinzuzufügen!
            </div>

            <div id="agency_standorte">
                <div class="mt-4 ms-3" v-for="(location, locationIndex) in locations">
                    <div>
                        <hr class="mt-3">
                        <div class="d-flex align-items-center justify-content-between mb-1">
                            <div>
                                <strong>Standort <strong class="counter me-1">{{ locationIndex+1 }}</strong> ({{ location.name || '...' }})</strong>
                                <p class="my-1 tincx-small text-muted">Bilder</p>
                            </div>
                            <a href="#" @click.prevent="locations.splice(locationIndex, 1)" class="ms-2 delete-field d-flex align-items-center justify-content-center">
                                <svg class="text-primary" width="17" height="21.8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 21.8">
                                    <path fill="currentcolor" d="M1.2,19.4c0,1.3,1.1,2.4,2.4,2.4h9.7c1.3,0,2.4-1.1,2.4-2.4V4.8H1.2v14.5Z"></path>
                                    <polygon fill="currentcolor" points="12.7 1.2 11.5 0 5.4 0 4.2 1.2 0 1.2 0 3.6 17 3.6 17 1.2 12.7 1.2"></polygon>
                                </svg>
                            </a>
                        </div>

                        <div class="row-small">
                            <div class="col-12 mt-2">
                                <Field
                                    type="text"
                                    rules="required"
                                    v-model="location.name"
                                    :name="`location.${locationIndex}.name`"
                                    :label="`Name Standort (${locationIndex+1})`"
                                    placeholder="Name eingeben*"
                                    :class="{ 'is-invalid': errors[`location[${locationIndex}].name`] }"
                                />
                                <ErrorMessage as="div" :name="`location[${locationIndex}].name`" class="invalid-feedback" />
                            </div>
                        </div>

                        <FileUpload 
                            class="mt-3"
                            :ref="`fileUploadLocation_${locationIndex}`"
                            v-model="location.files"
                            accept=".jpg,.png,.jpeg"
                            :multiple="true"
                            :imageIcons="true"
                            :maxFiles="10"
                            :imagePreview="true"
                        />
                        <Field type="hidden" :name="`location.${locationIndex}.files`" :value="location.files" />

                        <div class="row-small mt-4">

                            <Field
                                type="hidden"
                                :name="`location.${locationIndex}.gps`"
                                :label="`GPS-Standort (${locationIndex})`"
                                v-model="location.gps"
                                rules="required"
                            />
                            <ErrorMessage
                                as="div"
                                :name="`location[${locationIndex}].gps`"
                                class="invalid-feedback d-block mb-1"
                            />

                            <MapForm
                                :ref="`map_location_${locationIndex}`"
                                @onAddressData="(data) => locations[locationIndex] = {...location, ...data}"
                                v-model="location.gps"
                            />

                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    v-model="location.street"
                                    :name="`location.${locationIndex}.street`"
                                    :label="`Straße Standort (${locationIndex+1})`"
                                    placeholder="Straße eingeben"
                                    :class="{ 'is-invalid': errors[`location[${locationIndex}].street`] }"
                                />
                                <ErrorMessage as="div" :name="`location[${locationIndex}].street`" class="invalid-feedback" />
                            </div>

                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    v-model="location.street_number"
                                    :name="`location.${locationIndex}.street_number`"
                                    :label="`Straße Nr. Standort (${locationIndex+1})`"
                                    placeholder="Nr. eingeben"
                                    :class="{ 'is-invalid': errors[`location[${locationIndex}].street_number`] }"
                                />
                                <ErrorMessage as="div" :name="`location[${locationIndex}].street_number`" class="invalid-feedback" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    v-model="location.postal_code"
                                    :name="`location.${locationIndex}.postal_code`"
                                    :label="`PLZ Standort (${locationIndex+1})`"
                                    placeholder="PLZ eingeben"
                                    :class="{ 'is-invalid': errors[`location[${locationIndex}].postal_code`] }"
                                />
                                <ErrorMessage as="div" :name="`location[${locationIndex}].postal_code`" class="invalid-feedback" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    v-model="location.city"
                                    :name="`location.${locationIndex}.city`"
                                    :label="`Ort Standort (${locationIndex+1})`"
                                    placeholder="Ort eingeben"
                                    :class="{ 'is-invalid': errors[`location[${locationIndex}].city`] }"
                                />
                                <ErrorMessage as="div" :name="`location[${locationIndex}].city`" class="invalid-feedback" />
                            </div>

                            <div class="col-12 col-sm-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    rules=""
                                    v-model="location.phone"
                                    :name="`location.${locationIndex}.phone`"
                                    :label="`Telefonnummer Standort (${locationIndex+1})`"
                                    placeholder="Telefonnummer eingeben"
                                    :class="{ 'is-invalid': errors[`location[${locationIndex}].phone`] }"
                                />
                                <ErrorMessage as="div" :name="`location[${locationIndex}].phone`" class="invalid-feedback" />
                            </div>
                            <div class="col-12 col-sm-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    rules="email"
                                    v-model="location.email"
                                    :name="`location.${locationIndex}.email`"
                                    :label="`E-Mail Standort (${locationIndex+1})`"
                                    placeholder="E-Mail eingeben"
                                    :class="{ 'is-invalid': errors[`location[${locationIndex}].email`] }"
                                />
                                <ErrorMessage as="div" :name="`location[${locationIndex}].email`" class="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-right mt-2 mt-sm-3">
                    <a href="#" @click.prevent="addLocation" class="duplicate-field btn btn-general text-nowrap align-items-center d-none d-sm-inline-flex"> + Hinzufügen </a>
                </div>
                <a href="#" @click.prevent="addLocation" class="ms-2 duplicate-field d-flex align-items-center justify-content-center d-sm-none">
                    <img width="50" height="50" src="/images/icons/icon-plus.svg" alt="Icon Add">
                </a>
            </div>
            <hr>
            <p class="my-2">
                <strong>FAQ - Häufig gestellte Fragen ({{  faqs.length }})</strong>
            </p>
            <div>
                <div class="mt-3 mt-sm-4 alert alert-warning" v-if="faqs.length==0">
                    Noch keine Fragen im FAQ-Bereich vorhanden. Klicke auf "Hinzufügen" um die erste Frage hinzuzufügen!
                </div>
                <div class="mt-3 mt-sm-4" v-for="(faq, faqIndex) in faqs">
                    <div class="d-flex">
                        <div class="col">
                            <Field
                                type="text"
                                rules="required"
                                v-model="faq.question"
                                :name="`faq.${faqIndex}.question`"
                                :label="`Frage  FAQ (${faqIndex+1})`"
                                placeholder="Frage eingeben"
                                :class="{ 'is-invalid': errors[`faq[${faqIndex}].question`] }"
                            />
                            <ErrorMessage as="div" :name="`faq[${faqIndex}].question`" class="invalid-feedback" />

                            <Field 
                                as="textarea"
                                :name="`faq.${faqIndex}.answer`"
                                v-model="faq.answer"
                                class="mt-2 mt-sm-3"
                                rows="5"
                                :label="`Antwort FAQ (${faqIndex+1})`"
                                rules="required"
                                placeholder="Antwort eingeben"
                                :class="{ 'is-invalid': errors[`faq[${faqIndex}].answer`] }"
                            ></Field>
                            <ErrorMessage as="div" :name="`faq[${faqIndex}].answer`" class="invalid-feedback" />
                        </div>
                        <div class="col-auto">
                            <a href="#" @click.prevent="faqs.splice(faqIndex, 1)" class="ms-2 delete-field d-flex align-items-center justify-content-center">
                                <svg class="text-primary" width="17" height="21.8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 21.8">
                                    <path fill="currentcolor" d="M1.2,19.4c0,1.3,1.1,2.4,2.4,2.4h9.7c1.3,0,2.4-1.1,2.4-2.4V4.8H1.2v14.5Z"></path>
                                    <polygon fill="currentcolor" points="12.7 1.2 11.5 0 5.4 0 4.2 1.2 0 1.2 0 3.6 17 3.6 17 1.2 12.7 1.2"></polygon>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="text-right mt-2 mt-sm-3">
                    <a href="#" @click.prevent="addFaq" class="duplicate-field btn btn-general text-nowrap align-items-center d-none d-sm-inline-flex"> + Hinzufügen </a>
                </div>
                <a href="#"  @click.prevent="addFaq" class="ms-2 duplicate-field d-flex align-items-center justify-content-center d-sm-none">
                    <img width="50" height="50" src="/images/icons/icon-plus.svg" alt="Icon Add">
                </a>
            </div>
            <hr>
            <h4 class="my-0">Kontakt- und Bewerbungsmöglichkeiten*</h4>
            <p class="text-muted mt-4" v-html="$t('fe.company_profile.contact_methods_description')"></p>
            <label class="d-flex align-items-center mt-2 mt-sm-1">
                <Field 
                    type="checkbox"
                    name="contact_methods"
                    value="email"
                    rules="required"
                />
                <span>{{ $t('validation.attributes.email') }}</span>
            </label>
            <label class="d-flex align-items-center mt-2 mt-sm-1">
                <Field rules="required" type="checkbox" name="contact_methods" value="form" /><span>{{ $t('validation.attributes.form') }}</span>
            </label>
            <label class="d-flex align-items-center mt-2 mt-sm-1">
                <Field rules="required" type="checkbox" name="contact_methods" value="phone" /><span>{{ $t('validation.attributes.phone') }}</span>
            </label>
            <label class="d-flex align-items-center mt-2 mt-sm-1">
                <Field rules="required" type="checkbox" name="contact_methods" value="individual_requests" /><span>{{ $t('validation.attributes.individual_requests') }}</span>
            </label>

            <hr id="preferences">

            <template v-for="(preferenceListItem, preferenceListItemIdex) in preferences.list" :key="preferenceListItem.name">
                <Field 
                    type="hidden"
                    :name="`selectedUserRatings_${preferenceListItem.name}`"
                    :label="`Präferenzen: ${preferenceListItemIdex+1}.`" 
                    v-model="selectedUserRatings[preferenceListItem.name]"
                    rules="required" />
                    <ErrorMessage :name="`selectedUserRatings_${preferenceListItem.name}`" class="invalid-feedback d-block" />
            </template>
            <Rating
                :title="preferences.title"
                :text="preferences.text"
                :text2="preferences.text2"
                :preferences="preferences.list"
                pagetype="profile"
                :showAllSteps="true"
                :company="true"
                v-model="selectedUserRatings"
            />
        </div>



        <hr>

        <div class="steps-box">
            <p><strong>{{ filloutPercentage }}%</strong> deines Unternehmensprofils ist ausgefüllt</p>
            <span class="steps d-block bg-light">
                    <span class="active-step d-block bg-black" :style="{width: `${filloutPercentage}%`}"></span>
                </span>
        </div>
        

        <button
            type="submit" 
            :class="{ disabled: Object.keys(errors).length || !meta.valid }"
            class="ms-auto d-flex align-items-center justify-content-center mt-5 btn btn-primary btn-large px-3 btn-te">
            Änderungen Speichern
            <svg class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                <path fill="#FFFFFF" d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"></path>
                <path fill="#FFFFFF" d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"></path>
            </svg>
        </button>
    </Form>
</template>
<script>
import axios from 'axios'
import emitter from '../plugins/mitt'
import Swal from 'sweetalert2'
import { Form, Field, ErrorMessage } from 'vee-validate'
import FileUpload from './inc/form/FileUpload.vue'
import Rating from './inc/form/Rating.vue'
import MapForm from "@/components/inc/form/MapForm.vue"
import ImageUploadContainer from "@/components/inc/form/ImageUploadContainer.vue"

export default {
    name: 'ProfileCompanyPage',
    components: {
        Form,
        Field,
        ErrorMessage,
        FileUpload,
        Rating,
        MapForm,
        ImageUploadContainer
    },
    inject: ['$t'],
    mounted: function () {
        this.loadData()
        this.ref
    },
    watch: {
        active: {
            handler(val) {
                if(this.notSaved) {
                    return
                }
                emitter.emit('company_profile', val)
            },
        },
        selectedUserRatings: {
            handler(val) {
                emitter.emit('selectedUserRatings_preferences', val)
            },
            deep: true
        }
    },
    computed: {
        preferences() {
            //todo: copied from JobAdvisor.vue, we need to refactor this ones
            return {
                title: this.$t('fe.company_profile.preferences.title'),
                text: this.$t('fe.company_profile.preferences.text'),
                text2: this.$t('fe.company_profile.preferences.text2'),
                list: [
                    { title: this.$t('fe.job_advisor.preferences.list_company.0.title'), name: 'money' },
                    { title: this.$t('fe.job_advisor.preferences.list_company.1.title'), name: 'career' },
                    { title: this.$t('fe.job_advisor.preferences.list_company.2.title'), name: 'homeoffice' },
                    { title: this.$t('fe.job_advisor.preferences.list_company.3.title'), name: 'location' },
                ],
            }
        },
        filloutPercentage: function() {
            var countSteps = 14;
            var computedSteps = 0;
            if(this.active) {
                computedSteps++
            }
            if(this.files.length) {
                computedSteps++
            }
            if(this.locations.length) {
                computedSteps++
            }
            if(this.faqs.length) {
                computedSteps++
            }
            if(Object.keys(this.selectedUserRatings).length==4) {
                computedSteps++
            }

            
            if(this.formValues) {
                var changeTriggers = ['logo', 'cover', 'title', 'subtitle', 'content', 'benefits', 'video_url', 'maincategory', 'contact_methods']
                changeTriggers.forEach(k => {
                    if(this.formValues[k] && typeof this.formValues[k] == 'object' && Object.keys(this.formValues[k]).length) {
                        computedSteps++
                    } else if(this.formValues[k] && typeof this.formValues[k] == 'number') {
                        computedSteps++
                    } else if(this.formValues[k] && this.formValues[k].length) {
                        computedSteps++
                    }
                })
            }

            //countSteps :  computedSteps = 100 : x
            return Math.round(computedSteps * 100 / countSteps)
        }
    },
    methods: {
        addFaq: function() {
            this.faqs.push({question: null, answer: null})
        },
        addLocation: function() {
            this.locations.push({name: null, street: null, street_number: null, postal_code: null, city: null, email: null, phone: null, files: [], gps: null })
        },
        onChangeFormValues: function() {
            if(this.$refs.form) {
                console.log(this.$refs.form.values)
                this.formValues = this.$refs.form.values
            } else {
                this.formValues = null
            }
        },
        onUpdateLocationFiles: function(files, locationIndex) {
            this.locations[locationIndex].files = [...files]
        },
        loadData: function () {
            if (this.loading) return
            this.loading = true
            axios
                .get('/api/profile/company-page')
                .then((response) => {
                    this.loading = false

                    this.options = response.data.options
                    
                    if(response.data.data) {
                        let d = response.data.data
                        if(d.notSaved) {
                            this.notSaved = true
                        }
                        this.active = d.active
                        this.files = d.form.files
                        if(d.selectedUserRatings) {
                            this.selectedUserRatings = d.selectedUserRatings
                        }
                        if(d.form.faq) {
                            this.faqs = d.form.faq
                        }
                        if(d.form.location) {
                            this.locations = d.form.location
                        }
                        if (this.$refs && this.$refs.form) {
                            this.$refs.form.setValues(response.data.data.form)
                            this.formValues = response.data.data.form
                        }
                    }
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error)
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },
        onInvalid: function ({ values, errors, results }) {
            this.formHasBeenSent = true
            let html =
                '<ul>' +
                Object.values(errors)
                    .map((e) => '<li>' + e + '</li>')
                    .join('') +
                '</ul>'
            Swal.fire({
                title: this.$t('generic.error'),
                html: html,
                icon: 'error',
            })
        },
        onSubmit: function (values) {
            this.formHasBeenSent = false
            Swal.fire({
                text: this.$t('generic.loading'),
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            })

            const selectedUserRatings = this.selectedUserRatings
            const active = this.active
            const files = this.files
            axios
                .post(
                    '/api/profile/company-page',
                    {
                        ...values,
                        active,
                        selectedUserRatings,
                        files,
                    }
                )
                .then((response) => {
                    console.log(response)
                    if (response.data.error) {
                        Swal.fire(this.$t('generic.error'), response.data.error, 'error')
                        return
                    }

                    //Swal.close()
                    Swal.fire({
                        icon: 'success',
                        text: $t('generic.save_alert'),
                        didClose: () => window.scrollTo(0,0)
                    })

                    this.notSaved = false



                    this.loadData()
                })
                .catch((error) => {
                    console.log(error)
                    if (error.status == 422) {
                        var errors = error.response.data.errors
                        var errorBag = {}
                        for (var field in errors) {
                            errorBag[field] = errors[field].join(', ')
                        }
                        this.$refs.form.setErrors(errorBag)

                        let html =
                            '<ul>' +
                            Object.values(errorBag)
                                .map((e) => '<li>' + e + '</li>')
                                .join('') +
                            '</ul>'
                        Swal.fire({
                            title: this.$t('generic.error'),
                            icon: 'error',
                            html: html,
                        })
                        return
                    }
                    Swal.fire(this.$t('generic.error'), this.$t('generic.error_try_again_later'), 'error')
                })
        },
    },
    data() {
        return {
            active: false,
            notSaved: false,
            selectedUserRatings: {},
            files: [],
            formValues: null,
            mainCategory: null,
            formHasBeenSent: false,

            options: {},
            faqs: [],
            locations: [],

            //nicetohave: profil vollständig x von x wie bei profil
            //nicetohave: auf der linken seite box mit aktiv / inaktiv anzeigen wie bei privat?
        }
    },
}
</script>