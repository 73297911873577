<template>
    <div class="mainbar text-box d-xl-flex">
        <div class="chat-box position-relative">
            <div class="chat-title text-no-underline d-flex align-items-center">
                <a
                    :href="`/profil/${this.type}/chat/`"
                    @click.prevent="$emit('replace-url', `/profil/${this.type}/chat/`)"
                    class="back-link d-flex d-md-none align-items-center tincx-p text-muted text-bold text-no-underline"
                >
                    <svg class="me-1" width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.5 15.5">
                        <path
                            fill="currentcolor"
                            d="M7.75,15.5c4.27,0,7.75-3.48,7.75-7.75S12.02,0,7.75,0,0,3.48,0,7.75s3.48,7.75,7.75,7.75ZM7.75,1.29c3.58,0,6.46,2.88,6.46,6.46s-2.88,6.46-6.46,6.46S1.29,11.33,1.29,7.75,4.17,1.29,7.75,1.29Z"
                        ></path>
                        <path
                            fill="currentcolor"
                            d="M6.46,10.53c.3.3.7.3.89,0s.3-.7,0-.89l-1.29-1.29h4.77c.4,0,.6-.3.6-.6s-.3-.6-.6-.6h-4.77l1.29-1.29c.3-.3.3-.7,0-.89s-.3-.2-.5-.2-.3,0-.5.2l-2.29,2.29h0c-.1.1-.2.3-.2.5s2.58,2.78,2.58,2.78Z"
                        ></path>
                    </svg>
                    {{ $t('fe.generic.back') }}
                </a>

                <div v-if="customer" class="logo-box">
                    <img
                        v-if="customer.type === 'private'"
                        width="20"
                        height="20"
                        src="/images/icons/icon-privatperson.svg"
                        alt="Icon Privatperson"
                    />
                    <img v-else-if="customer.logo" :src="customer.logo" :alt="'Logo ' + customer.display_name" />
                    <strong v-else class="text-muted text-center">{{ customer.display_name }}</strong>
                </div>
                <p v-if="entry" class="ms-2 ms-xs-3 m3-0 mx-md-2 mx-lg-3 text-ellipsis">
                    <strong>{{ entry.title }}</strong>
                </p>

                <div class="justify-content-between mt-1 d-flex ms-auto action-box">
                    <a
                        href="#"
                        @click.prevent="$emit('archive-chat', this.chatId)"
                        class="action-button text-no-underline d-flex align-items-center"
                    >
                        <img src="/images/icons/icon-archiv.svg" alt="Icon Archiv" />
                        <span>{{ $t('fe.generic.archive') }}</span>
                    </a>
                    <a
                        href="#"
                        @click.prevent="$emit('trash-chat', this.chatId)"
                        class="me-1 me-md-0 ms-3 ms-lg-4 action-button text-no-underline d-flex align-items-center"
                    >
                        <img src="/images/icons/icon-trash.svg" alt="Icon Reply" />
                        <span>{{ $t('fe.generic.delete') }}</span>
                    </a>
                </div>
            </div>

            <div class="chat">
                <template v-for="message in this.messages">
                    <small class="d-block text-center text-muted">{{ message.date }}</small>
                    <p :class="{ mychat: !message.other, yourchat: message.other }" v-html="message.text"></p>
                </template>
            </div>

            <Form class="d-flex align-items-center chat-input position-absolute" ref="form" novalidate @submit="postMessage">
                <Field as="textarea" name="message" rows="1" class="col search-text" :placeholder="$t('fe.chat.message_placeholder')" />

                <button type="submit" class="ms-2 btn btn-bs btn-primary d-flex align-items-center justify-content-center">
                    <svg width="24.9" height="24.9" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.9 24.9">
                        <path
                            fill="#fff"
                            d="M24.7,11.7c-.2-.4-.5-.7-.8-.8h0S2,.1,2,.1C1.3-.2.5,0,.1.8,0,1,0,1.2,0,1.4h0c0,.2,0,.4,0,.6l2.1,8.4c0,.2.3.4.5.4l9.2,1c.3,0,.6.4.6.7,0,.3-.3.5-.6.6l-9.2,1c-.2,0-.4.2-.5.4L0,22.9c0,.2,0,.4,0,.5h0c0,.8.6,1.4,1.4,1.4.2,0,.4,0,.6-.1l21.9-10.7c.9-.4,1.3-1.5.8-2.4Z"
                        />
                    </svg>
                </button>
            </Form>
        </div>

        <div v-if="entry" class="info">
            <h5 class="mt-0 mb-3">{{ $t(`fe.category.${entry.type}.entry_description`) }}</h5>
            <p>{{ entry.description }}</p>
            <a :href="entry.url" target="_blank" class="btn align-items-center d-flex text-start btn-transparent">
                {{ $t('fe.generic.learn_more') }}
                <svg width="15.6" height="15.6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.6 15.6">
                    <path
                        fill="#231f20"
                        d="M7.8,0C3.5,0,0,3.5,0,7.8s3.5,7.8,7.8,7.8,7.8-3.5,7.8-7.8S12.1,0,7.8,0ZM7.8,14.3c-3.6,0-6.5-2.9-6.5-6.5S4.2,1.3,7.8,1.3c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5Z"
                    ></path>
                    <path
                        fill="#231f20"
                        d="M11.4,7.3s0,0,0,0l-2.3-2.3c-.3-.3-.7-.3-.9,0s-.3.7,0,.9l1.3,1.3h-4.8c-.4,0-.6.3-.6.6s.3.6.6.6h4.8l-1.3,1.3c-.3.3-.3.7,0,.9s.3.2.5.2.3,0,.5-.2l2.3-2.3s0,0,0,0c.1-.1.2-.3.2-.5s0-.4-.2-.5Z"
                    ></path>
                </svg>
            </a>
            <template v-if="customer">
                <div v-if="customer.type === 'private'" class="mt-3">
                    <ContactUser :customer="customer" :entryType="entry.type" />
                </div>
                <div v-else-if="customer.type === 'business'">
                    <h3 class="tincx-h5 mt-4">{{ $t(`fe.request.${entry.type}.contact_person`) }}</h3>

                    <div class="d-block d-xs-flex d-xl-block">
                        <div v-if="customer.logo" class="contact-image me-xs-3 me-xl-0 mb-3 mb-xs-0 mb-xl-3">
                            <img src="{{ customer.logo }}" />
                        </div>
                        <div>
                            <p class="mt-0">
                                <strong>{{ customer.display_name }}</strong>
                                <br />
                                <!--                            Abteilung -->
                            </p>
                            <p class="my-0">
                                <a :href="`mailto:${customer.email}`">{{ customer.email }}</a>
                                <br />
                                Tel. <a :href="`tel:${customer.phone.replace(' ', '').replace('+', '00')}`">{{ customer.phone }}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import ContactUser from '@/components/inc/ContactUser.vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { required } from '@vee-validate/rules'

export default {
    name: 'ChatItem',
    components: { Form, Field, ContactUser },
    data() {
        return {
            entry: null,
            customer: null,
            messages: [],
        }
    },
    created() {
        this.fetchMessages()
    },
    methods: {
        required,
        async fetchMessages() {
            try {
                const response = await fetch(`/api/profil/${this.type}/chat/e-${this.entryId}/${this.chatId ?? ''}`)
                const data = await response.json()
                this.entry = data.entry
                this.customer = data.customer
                this.messages = data.messages
            } catch (error) {
                console.error('Fehler beim Laden des Chats:', error)
            }
        },
        async postMessage(form, { resetForm }) {
            if (!form.message) {
                return false
            }
            try {
                const response = await fetch(`/api/profil/${this.type}/chat/e-${this.entryId}/${this.chatId ?? ''}`, {
                    method: 'POST',
                    body: JSON.stringify(form),
                })
                const data = await response.json()
                this.entry = data.entry
                this.customer = data.customer
                this.messages = data.messages
                resetForm()
            } catch (error) {
                console.error('Fehler beim Absenden', error)
            }
            this.$emit('message-sent')
        },
    },
    props: {
        chatEntries: {
            type: Array,
            default: [],
        },
        type: {
            type: String,
            required: true,
        },
        entryId: {
            type: Number,
            required: true,
        },
        chatId: {
            type: Number,
            default: null,
        },
    },
}
</script>
